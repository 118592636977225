import React from "react";
import PropTypes from "prop-types";

export function Select({ content }) {
  return (
    <div>
      <label htmlFor={content.id}>{content.label}</label>
      <select name={content.fieldId} id={content.id}>
        <option value="" selected>
          {content.placeholder}
        </option>
        {content.options &&
          content.options.map(([optionLabel, optionValue]) => (
            <option key={optionLabel} value={optionValue}>
              {optionLabel}
            </option>
          ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    fieldId: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Select;
