import React from "react";
import PropTypes from "prop-types";

export function Text({ content }) {
  return <p>{content.label}</p>;
}

Text.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default Text;
