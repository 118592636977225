import React from "react";
import PropTypes from "prop-types";
import { Flex, Spinner } from "@wingmate/toolkit";
import { CheckmarkIcon } from "wm-graphics";

export function Button({ content, formState }) {
  const { isSubmitted, isSubmitting } = formState;

  return (
    <Flex align="center" gap={4}>
      <input type="submit" value={content.label} disabled={isSubmitting} />
      {isSubmitting && <Spinner size={24} />}
      {isSubmitted && <CheckmarkIcon size={24} />}
    </Flex>
  );
}

Button.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  formState: PropTypes.shape({
    isSubmitted: PropTypes.bool,
    isSubmitting: PropTypes.bool,
  }),
};

export default Button;
