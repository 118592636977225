import React from "react";
import PropTypes from "prop-types";
import { Button } from "./Contents/Button";
import { Input } from "./Contents/Input";
import { Select } from "./Contents/Select";
import { Text } from "./Contents/Text";

export function Content({ content, formState }) {
  switch (content.type) {
    case "button":
      return <Button content={content} formState={formState} />;
    case "input":
      return <Input content={content} />;
    case "select":
      return <Select content={content} />;
    case "text":
      return <Text content={content} />;
    default:
  }
}

Content.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
  }).isRequired,
  formState: PropTypes.shape({
    isSubmitted: PropTypes.bool,
    isSubmitting: PropTypes.bool,
  }),
};

export default Content;
