import React from "react";
import PropTypes from "prop-types";

export function Input({ content }) {
  return (
    <div>
      <label htmlFor={content.fieldId}>{content.label}</label>
      <input
        id={content.fieldId}
        name={content.fieldId}
        type="text"
        placeholder={content.placeholder}
      />
    </div>
  );
}

Input.propTypes = {
  content: PropTypes.shape({
    fieldId: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default Input;
