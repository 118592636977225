import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Flex } from "@wingmate/toolkit";
import { Content } from "./Content";

import "./LeadFormPresenter.scss";

export const LEAD_FORM_SUBMISSIONS_ENDPOINT = (leadFormId) =>
  `/app/v1.0/lead_forms/${leadFormId}/submissions`;

export function LeadFormPresenter({ leadForm }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { formattedContent, redirectTo } = leadForm.attributes;

  const getUrlParams = () => {
    const currentURL = window.location.href;

    if (currentURL.includes("?")) {
      return `?${currentURL.slice(currentURL.indexOf("?") + 1)}`;
    }
    return "";
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();

    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData.entries());

    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    try {
      await axios.post(LEAD_FORM_SUBMISSIONS_ENDPOINT(leadForm.id), payload, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
          Accept: "application/json",
        },
      });

      setIsSubmitting(false);
      setIsSubmitted(true);

      if (redirectTo) {
        const urlParamsString = getUrlParams();

        let hrefLink;
        if (!redirectTo.includes("https://")) {
          hrefLink = `//${redirectTo}`;
        } else {
          hrefLink = redirectTo;
        }

        setTimeout(() => {
          window.location.href = `${hrefLink}${urlParamsString}`;
        }, 1000);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderContent = () => {
    return formattedContent.map((contentItem) => (
      <Content
        key={contentItem.id}
        content={contentItem}
        formState={{ isSubmitting, isSubmitted }}
      />
    ));
  };

  return (
    <form className="LeadFormPresenter" onSubmit={handleSubmit}>
      <Flex gap={16} vertical>
        {renderContent()}
      </Flex>
    </form>
  );
}

LeadFormPresenter.propTypes = {
  leadForm: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    attributes: PropTypes.shape({
      formattedContent: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          type: PropTypes.string,
        })
      ).isRequired,
      redirectTo: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default LeadFormPresenter;
